import localForage from "localforage"
import { createI18n } from "vue-i18n"
import { app } from "./frame"
import { LOCALIZED_PLATFORMS } from "../constants/platform"
import { walmartTipsKey } from "../config/locale"

const LOCALE_ALL = ["EN", "ZH", "JA"]

const productLine = localStorage.getItem("productline")
const { INDEXEDDB } = localForage
const forageInstance = localForage.createInstance({
  driver: INDEXEDDB,
  name: "PacvueLocale",
  storeName: productLine.startsWith("commerce") ? "db_commerce" : "db_ads",
  description: "locale messages cached in local store"
})

if (!LOCALIZED_PLATFORMS.includes(productLine)) {
  localStorage.setItem("locale", "EN")
}

const locale = (window.locale = localStorage.getItem("locale") || "EN")

let i18n
if (!["commerce-walmart"].includes(productLine)) {
  // 新国际化加载方案
  i18n = createI18n({
    locale: "pacvue-i18n",
    messages: { "pacvue-i18n": window[`locale_${locale}`] || {} },
    missing: function (a, b, c) {
      return `{122}${b.toString()}`
    },
    globalInjection: true
  })
} else {
  // legacy
  const messages = {
    EN: Object.assign({}, window.locale_EN || {}),
    ZH: Object.assign({}, window.locale_ZH || {}),
    JA: Object.assign({}, window.locale_JA || {})
  }
  i18n = createI18n({
    locale: localStorage.getItem("locale") || "EN",
    messages: messages,
    missing: function (a, b, c) {
      return `{122}${b.toString()}`
    },
    globalInjection: true
  })
}

const $t = (window.$t = function (v, x) {
  if (window.isShowI18n) {
    console.error(v + " 文案:" + i18n.global.t(v, x))
  }
  return i18n.global.t(v, x)
})

/**
 * walmart特殊文案转换
 * @param {String} text 传入的文案
 * @param {Boolean} transform 是否开启强制转换
 */
const mappingRules = (text, transform) => {
  if (productLine === "samsclub") {
    return `Online${walmartTipsKey.includes(text) ? "" : " "}${text}`
  }
  const router = app.config.globalProperties.router
  let settingStore
  // Share Link 页面单独取 key 判断逻辑
  if (["/Share/Index", "/Share/SOVDashboard"].includes(router.currentRoute.value.path)) {
    settingStore = localStorage.getItem("ShareLink_SettingStore")
  } else {
    const uid = localStorage.getItem("uid")
    settingStore = localStorage.getItem(`${uid}_SettingStore`)
  }
  // 判断条件后续替换为接口返回
  if (settingStore === "true") {
    if (productLine === "walmart" || transform) {
      return `Online${walmartTipsKey.includes(text) ? "" : " "}${text}`
    }
  } else {
    return text
  }
}

// Walmart Custom Translation
const $wt = (window.$wt = function (v, x, fillArgs) {
  return i18n.global.t(mappingRules(v, x), fillArgs)
})

export { LOCALE_ALL, locale, i18n, $t, forageInstance, $wt }
